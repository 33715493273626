<div class="container-full">
  <div class="row">
    <div class="col-md-8">
      <div _ngcontent-aoc-c205="" class="title">
        <h2 _ngcontent-aoc-c205="">
          Informe o valor dos seus aportes médios anuais:
        </h2>
        <p _ngcontent-aoc-c205="">
          Na opção aportes dinâmicos, você pode alterar os valores dos aportes
          anuais, considerando eventuais mudanças na composição do padrão de
          vida e disponibilidade para investimentos.
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <button
        mat-stroked-button
        style="
          margin-bottom: 12px;
          float: right;
          margin-top: 12px;
          border-radius: 2px;
          background-color: #00acc1;
        "
        [routerLink]="['/painel-controle']"
      >
        <mat-icon
          style="
            color: #fff;
            font-size: 16px;
            padding-top: 5px;
            padding-left: 8px;
          "
          >arrow_back
        </mat-icon>
        <strong style="padding-right: 8px; color: #fff; padding-bottom: 5px"
          >Voltar a tela de configurações</strong
        >
      </button>
    </div>
  </div>

  <div style="margin-bottom: 12px" class="card-painel">
    <div class="heading-internal" style="margin-bottom: 0px">
      <div class="heading-surface"></div>
      <h2 class="heading-content" style="flex: 1">
        Informações

        <!--container-->
      </h2>
    </div>

    <div class="card-content">
      <form>
        <div class="alert alert-primary" role="alert">
          Atenção, essas informações são as mesmas utilizadas no painel de
          controle.
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">
            <div class="title-label">Período de Aplicação:</div>
          </label>
          <div class="col-sm-3">
            <mat-form-field>
              <span matSuffix><b>&nbsp;&nbsp; Anos</b></span>
              <input
                matInput
                class="right"
                type="number"
                autofocus
                [formControl]="formGroup.get('periodoDeAplicacao')"
                [max]="50"
                [min]="0"
                (input)="onInputChange($event)"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <div class="description">
              <ul></ul>
            </div>
          </div>
        </div>
        <hr />
        <div class="green-content">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">
              <div class="title-label">Corrigir aportes pela inflação:</div>
            </label>
            <div class="col-sm-3">
              <mat-slide-toggle
                style="padding-top: 20px"
                [checked]="
                  formGroup
                    .get('advancedConfiguration')
                    .get('fixInflationOnAporte').value
                "
                [formControl]="
                  formGroup
                    .get('advancedConfiguration')
                    .get('fixInflationOnAporte')
                "
              >
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6">
              <div class="description">
                <ul>
                  <li>
                    <span
                      >Faça com que os aportes cresçam na mesma medida da
                      inflação desejada.</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              formGroup.get('advancedConfiguration').get('fixInflationOnAporte')
                .value
            "
            class="form-group row"
          >
            <label class="col-sm-3 col-form-label">
              <div class="title-label">Inflação:</div>
            </label>
            <div class="col-sm-3">
              <app-input-yeary-or-montly
                [disableType]="true"
                [formGroup]="formGroup.get('inflacao')"
                [suffix]="'%'"
              >
              </app-input-yeary-or-montly>
            </div>
            <div class="col-sm-6">
              <div class="description">
                <ul>
                  <li>
                    <span
                      >Use a Meta do Banco Central do Brasil (bcb.gov.br).</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="heading-internal" style="margin-bottom: 0px; margin-top: 0px">
      <div class="heading-surface"></div>
      <h2 class="heading-content" style="flex: 1">
        Projeções:
        <span
          matTooltip="Altere a seguir os valores anuais dos aportes que serão projetados no planejamento patrimonial de longo prazo."
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
              fill="black"
            ></path>
          </svg>
        </span>
        <!--container-->
      </h2>
    </div>
    <div class="card-content">
      <div class="row">
        <div class="col-md-12">
          <div style="min-width: 100%; overflow: auto">
            <table
              style="margin-top: 12px; min-width: 420px"
              class="table table-striped"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 200px; letter-spacing: 1px">
                    Período:
                  </th>
                  <th>
                    Valor dinâmico:
                    <span
                      matTooltip="Adicione os valores que deseja aportar anualmente."
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.26372 5.64398L8.86369 5.65378L8.87349 4.05381L7.27352 4.04401L7.26372 5.64398ZM8.00982 14.4487C4.48189 14.4271 1.62753 11.5376 1.64913 8.00965C1.67073 4.48171 4.56026 1.62735 8.0882 1.64896C11.6161 1.67056 14.4705 4.56009 14.4489 8.08802C14.4273 11.616 11.5378 14.4703 8.00982 14.4487ZM8.09799 0.0489861C7.04744 0.0425531 6.0059 0.243105 5.03286 0.639192C4.05981 1.03528 3.1743 1.61914 2.4269 2.35745C0.917449 3.84853 0.0621504 5.87816 0.0491585 7.99985C0.0361666 10.1215 0.866546 12.1615 2.35762 13.6709C3.09593 14.4183 3.97422 15.013 4.94234 15.421C5.91047 15.829 6.94947 16.0423 8.00002 16.0487C10.1217 16.0617 12.1617 15.2313 13.6711 13.7402C15.1806 12.2491 16.0359 10.2195 16.0489 8.09782C16.0553 7.04727 15.8547 6.00573 15.4587 5.03268C15.0626 4.05963 14.4787 3.17413 13.7404 2.42673C13.0021 1.67932 12.1238 1.08466 11.1557 0.676685C10.1876 0.268711 9.14855 0.055419 8.09799 0.0489861ZM7.22453 12.0439L8.8245 12.0537L8.85389 7.25375L7.25392 7.24395L7.22453 12.0439Z"
                          fill="black"
                        ></path>
                      </svg>
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of formYear.controls | slice : 0 : numberOfInputs;
                    let i = index
                  "
                >
                  <th
                    style="
                      width: 200px;
                      padding-top: 22px;
                      padding-bottom: 22px;
                    "
                    scope="row"
                  >
                    Ano {{ i + 1 }}
                  </th>
                  <td *ngIf="apolo.isDynamic" style="padding: 0px 12px">
                    <div style="display: inline-flex">
                      <app-input-number
                        style="max-width: 280px; flex: 0"
                        [prefix]="'R$'"
                        [control]="item.get('dynamic')"
                      >
                      </app-input-number>
                      <div>
                        <button
                          (click)="applyToAll(item)"
                          style="
                            margin-top: 12px;
                            font-size: 12px;
                            margin-left: 6px;
                            background: #fff;
                          "
                          mat-stroked-button
                        >
                          Aplicar a todos
                        </button>
                      </div>
                    </div>
                    <p style="font-size: 12px; color: #666; margin-top: -12px">
                      Lembre-se de colocar o valor do aporte anual completo.
                    </p>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8"></div>
    <div class="col-md-4">
      <button
        mat-stroked-button
        style="
          margin-bottom: 12px;
          float: right;
          margin-top: 12px;
          border-radius: 2px;
          background-color: #00acc1;
        "
        [routerLink]="['/painel-controle']"
      >
        <mat-icon
          style="
            color: #fff;
            font-size: 16px;
            padding-top: 5px;
            padding-left: 8px;
          "
          >arrow_back
        </mat-icon>
        <strong style="padding-right: 8px; color: #fff; padding-bottom: 5px"
          >Voltar a tela de configurações</strong
        >
      </button>
    </div>
  </div>
</div>
