export const environment = {
  production: true,
  url: 'https://api.qa.empreenderdinheiro.com.br/',
  baseUrl: 'qa.empreenderdinheiro.com.br',
  authority: 'https://api.qa.empreenderdinheiro.com.br/auth/oidc',
  clientId: dynamicClientId('76d30b0e-2a8e-11ec-8d3d-0242ac130003'),
};

export function dynamicClientId(clientIdDefault: string) {
  const techFinanceClient = '274584ee-488a-11ec-81d3-0242ac130003';
  if ((window.location as any).href.toString().split('local').length > 1) {
    return '483bef78-4707-11ec-81d3-0242ac130003';
  }
  if ((window.location as any).href.toString().split('tf').length > 1) {
    return techFinanceClient;
  }
  if ((window.location as any).href.toString().split('techfinance').length > 1) {
    return techFinanceClient;
  }
  return clientIdDefault;
}
