<h2 style="font-weight: bold; margin-top: 5px">
  Seja bem-vindo ao Sistema Apolo!
</h2>
<iframe
  class="col-12 col-md-12"
  src="https://player.vimeo.com/video/586446462"
  width="100%"
  height="70%"
  frameborder="0"
  allow="autoplay; fullscreen; picture-in-picture"
  allowfullscreen
>
</iframe>
<button
  style="float: right; outline: none"
  mat-stroked-button
  color="secondary"
  (click)="onNoClick()"
>
  Fechar
</button>
